@mixin a($e) { @extend [data-a~="#{$e}"]; }
@mixin d($e) { @extend [data-d~="#{$e}"]; }
@mixin g($e) { @extend [data-g~="#{$e}"]; }





// Main title
[data-p~="t:1"] {
	@include d("ft-fy=main-rr");
	@include d("ft-se=30rem");
	// @include d("lr-sg=1rem");
	@include d("le-ht=1");
}

// Text read
[data-p~="t:2"] {
	@include d("ft-fy=main-rr");
	@include d("ft-se=14rem");
	// @include d("lr-sg=1rem");
	@include d("le-ht=1.4");
}

// Text small
[data-p~="t:3"] {
	@include d("ft-fy=main-rr");
	@include d("ft-se=11rem");
	// @include d("lr-sg=1rem");
	@include d("le-ht=1.2");
}

// Medium title
[data-p~="t:4"] {
	@include d("ft-fy=main-rr");
	@include d("ft-se=20rem");
	// @include d("lr-sg=1rem");
	@include d("le-ht=1");
}





// [data-p~="content"] {
// 	@include d("ft-fy=main-rr");
// 	@include d("ft-se=14rem");
// 	@include d("le-ht=1.8");

// 	@include d("@2000=ft-se=12rem");

// 	a {
// 		color: var(--color-15);

// 		&:hover {
// 			@include d("tt-dn=ue");
// 		}
// 	}
// 	p:not(:first-child) {
// 		@include d("mn-tp=10px");
// 	}
// 	ul {
// 		@include d("lt-se-te=il");
// 		@include d("pg-lt=15px");
// 	}
// 	> ul:not(:first-child) {
// 		@include d("mn-tp=10px");
// 	}
// 	ol {
// 		@include d("lt-se-te=il");
// 		@include d("pg-lt=15px");
// 	}
// 	img {
// 		@include d("mx-wh=100%");
// 	}
// 	iframe {
// 		@include d("mx-wh=100%");
// 	}
// 	table {
// 		@include d("mx-wh=100%");
// 	}
// }



// Checkbox
[data-p~="1:1"] {
	&:hover,
	&:focus-within,
	&:checked {
		+ [data-p~="1:2"] {

			[data-p~="1:3"] {
				background-color: var(--color-bk);

				[data-p~="1:4"] {
					color: var(--color-we);
				}
			}
		}
	}
}

// Breadcrumb
[data-p~="2:1"] {
	&:nth-child(even) {
		[data-p~="2:2"] {
			background-color: var(--color-1);
		}
		[data-p~="2:3"] {
			border-left-color: var(--color-1);
		}
	}
	&:nth-child(odd) {
		[data-p~="2:2"] {
			background-color: var(--color-3);
		}
		[data-p~="2:3"] {
			border-left-color: var(--color-3);
		}
	}
}

// Carousel
[data-p~="3:1"] {
    background-color: var(--color-8) !important;

    > span {
        background-color: var(--color-we) !important;
    }
}

[data-p~="3:2"] {
    background-color: var(--color-9) !important;

    > span {
        background-color: var(--color-bk) !important;
    }
}

[data-p~="4:1"] {
	&:focus,
	&:not(:placeholder-shown) {
		~ [data-p~="10:2"] {
			bottom: 100%;

			[data-p~="10:3"] {
				font-size: 11rem;
			}
		}
	}
}

[data-p~="5:1"] {
	appearance: none;
	cursor: pointer;

	&::-webkit-slider-runnable-track {
		background-color: var(--color-we);
		border-radius: 15px;
		height: 30px;
	}
	&::-moz-range-track {
		background-color: var(--color-we);
		border-radius: 15px;
		height: 30px;
	}
	&::-webkit-slider-thumb {
		appearance: none; 
		background-color: var(--color-theme-1);
		border-radius: 100%;
		width: 30px;
		height: 30px;
	}
	&::-moz-range-thumb {
		background-color: var(--color-theme-1);
		border-radius: 100%;
		width: 30px;
		height: 30px;
	}
}
