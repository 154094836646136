@font-face {
    font-family: "WorkSans-Regular";
    src: url("/build/fonts/WorkSans/WorkSans-Regular.ttf") format("truetype");
    font-display: block;
}

@font-face {
    font-family: "WorkSans-Medium";
    src: url("/build/fonts/WorkSans/WorkSans-Medium.ttf") format("truetype");
    font-display: block;
}

@font-face {
    font-family: "WorkSans-Bold";
    src: url("/build/fonts/WorkSans/WorkSans-Bold.ttf") format("truetype");
    font-display: block;
}



@font-face {
    font-family: "Poppins-Regular";
    src: url("/build/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
    font-display: block;
}

@font-face {
    font-family: "Poppins-Bold";
    src: url("/build/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
    font-display: block;
}
